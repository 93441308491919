import {useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import {About, BodySections, HeroSection, Newsletter} from 'modules/home'
import {addApolloState, initializeApollo} from 'helpers/ApolloHelper/ApolloHelper'
import Page from '_app/components/Page/Page'
import {Context} from '_app/types/context'
import {useHomePage} from 'modules/home/hooks/useHomePage'
import withAppInit from '_app/hoc/withAppInit'
import {EdroneEvents} from '_app/utils/edrone'
import MagicLoader from '../components/atoms/MagicLoader/MagicLoader'
import I18nHelper from '../helpers/I18nHelper/I18nHelper'
import SeoHelper from '../helpers/SeoHelper/SeoHelper'
import TrustBanner from '../components/molecules/TrustBanner/TrustBanner'
import DialogHomePage from '../components/atoms/DialogHomePage/DialogHomePage'
import LocalStorageHelper from '../helpers/LocalStorageHelper/LocalStorageHelper'
import {APP_ROUTES} from '../_app/routes'
import RequestEnum from '../enums/RequestEnum/RequestEnum'
import {GET_HOME_PAGE} from '../_app/graphql/cms/queries/GetHomePage'
import BannerPromotion from '../components/molecules/BannerPromotion/BannerPromotion'
import BreakpointsHelper from '../helpers/BreakpointsHelper/BreakpointsHelper'

const Index = ({hasNoIndex, hasNoFollow }: any) => {
    const router = useRouter()
    const {locale} = router
    const apolloClient = initializeApollo(null, {})
    const [cms, setCms] = useState({})
    const [loading, setLoading] = useState(true)
    const {
        hero,
        opinions,
        newsletter,
        sections,
        mobileVideoUrl,
        mobileVideoCopy,
    } = useHomePage(cms)
    const handleCms = async () => {
        // const {data: tempDAta}: any = await apolloClient.query({
        //     query: GET_HOME_PAGE,
        //     variables: {
        //         locale,
        //     },
        //     context: {
        //         clientName: RequestEnum.CMS_PUBLIC,
        //     },
        // })
        let data: any = {
            'homePage': {
                'data': {
                    'id': '3',
                    '__typename': 'HomePageEntity',
                    'attributes': {
                        'hero': [
                            {
                                'id': '183',
                                'title': 'Kolekcja Icons',
                                'description': null,
                                'color': '#ffffff',
                                'bg': 'transparent',
                                'alignment': 'start',
                                'cta': {
                                    'id': '286',
                                    'text': 'Odkryj',
                                    'link': {
                                        'id': '1369',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': null,
                                        'href': '/sklep/kolekcje/kolekcja-icons',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    'variant': 'solid',
                                    'reversed': true,
                                    '__typename': 'ComponentPartsCta'
                                },
                                'link': null,
                                'img': {
                                    'data': {
                                        'id': '3909',
                                        'attributes': {
                                            'alternativeText': null,
                                            'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/b48b3001-3178-489c-f48a-983449b3f200/public',
                                            'width': 1920,
                                            'height': 820,
                                            '__typename': 'UploadFile'
                                        },
                                        '__typename': 'UploadFileEntity'
                                    },
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'img_mobile': {
                                    'data': {
                                        'id': '3896',
                                        'attributes': {
                                            'alternativeText': null,
                                            'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/2a296e09-dc36-40ad-4e41-a59b5d68bb00/public',
                                            'width': 1080,
                                            'height': 1613,
                                            '__typename': 'UploadFile'
                                        },
                                        '__typename': 'UploadFileEntity'
                                    },
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'variant': null,
                                'section_title': 'Celebracja Kobiecości',
                                '__typename': 'ComponentSectionsBanner'
                            },
                            {
                                'id': '184',
                                'title': 'Kolekcja Miyuki',
                                'description': null,
                                'color': '#fefcfe',
                                'bg': 'transparent',
                                'alignment': 'start',
                                'cta': {
                                    'id': '287',
                                    'text': 'Odkryj',
                                    'link': {
                                        'id': '1370',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': null,
                                        'href': '/sklep/kolekcje/kolekcja-miyuki',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    'variant': 'solid',
                                    'reversed': true,
                                    '__typename': 'ComponentPartsCta'
                                },
                                'link': null,
                                'img': {
                                    'data': {
                                        'id': '3897',
                                        'attributes': {
                                            'alternativeText': null,
                                            'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/a6ffb7ea-2f4a-42f2-b350-329b9b552600/public',
                                            'width': 1920,
                                            'height': 820,
                                            '__typename': 'UploadFile'
                                        },
                                        '__typename': 'UploadFileEntity'
                                    },
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'img_mobile': {
                                    'data': {
                                        'id': '3898',
                                        'attributes': {
                                            'alternativeText': null,
                                            'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/780f41d1-cfd8-49cb-0e95-afb4e782be00/public',
                                            'width': 1080,
                                            'height': 1613,
                                            '__typename': 'UploadFile'
                                        },
                                        '__typename': 'UploadFileEntity'
                                    },
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'variant': null,
                                'section_title': 'Kolekcja Icons',
                                '__typename': 'ComponentSectionsBanner'
                            },
                            {
                                'id': '184',
                                'title': 'Wiosenne inspiracje',
                                'description': null,
                                'color': '#fefcfe',
                                'bg': 'transparent',
                                'alignment': 'start',
                                'cta': {
                                    'id': '287',
                                    'text': 'Odkryj',
                                    'link': {
                                        'id': '1370',
                                        'external_href': null,
                                        'target': 'self',
                                        'text': null,
                                        'href': '/sklep/polecane/inspiracje/spring-trends',
                                        'rel': null,
                                        '__typename': 'ComponentPartsLink'
                                    },
                                    'variant': 'solid',
                                    'reversed': true,
                                    '__typename': 'ComponentPartsCta'
                                },
                                'link': null,
                                'img': {
                                    'data': {
                                        'id': '3897',
                                        'attributes': {
                                            'alternativeText': null,
                                            'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/aa41ab67-6289-45c3-08d0-86a8214be900/public',
                                            'width': 1920,
                                            'height': 820,
                                            '__typename': 'UploadFile'
                                        },
                                        '__typename': 'UploadFileEntity'
                                    },
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'img_mobile': {
                                    'data': {
                                        'id': '3898',
                                        'attributes': {
                                            'alternativeText': null,
                                            'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/5c0b66d4-3119-40ff-7f30-d57953da2000/public',
                                            'width': 1080,
                                            'height': 1613,
                                            '__typename': 'UploadFile'
                                        },
                                        '__typename': 'UploadFileEntity'
                                    },
                                    '__typename': 'UploadFileEntityResponse'
                                },
                                'variant': null,
                                'section_title': 'Kolekcja Icons',
                                '__typename': 'ComponentSectionsBanner'
                            },
                        ],
                        'sections': [
                            {
                                '__typename': 'ComponentSectionsGrid',
                                'id': '1',
                                'section_title': '',
                                'items': [
                                    {
                                        'id': '1',
                                        'title': 'Bransoletki damskie',
                                        'link': {
                                            'id': '122',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/bizuteria/bransoletki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3899',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/93796427-2d7e-471f-70a6-220ccf252000/public',
                                                    'width': 689,
                                                    'height': 689,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                    {
                                        'id': '4',
                                        'title': 'Pierścionki damskie',
                                        'link': {
                                            'id': '124',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/bizuteria/pierscionki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3900',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/ca4c965d-5c2b-4bdd-5476-96c480861800/public',
                                                    'width': 330,
                                                    'height': 330,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                    {
                                        'id': '3',
                                        'title': 'Kolczyki damskie',
                                        'link': {
                                            'id': '120',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/bizuteria/kolczyki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3794',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': ' https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/21989710-9916-4539-629f-8723e5e0c800/public',
                                                    'width': 330,
                                                    'height': 330,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                    {
                                        'id': '2',
                                        'title': 'Naszyjniki damskie',
                                        'link': {
                                            'id': '121',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/bizuteria/naszyjniki',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3901',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/8204712b-4f62-44c7-d613-1f149c751f00/public',
                                                    'width': 330,
                                                    'height': 330,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                    {
                                        'id': '5',
                                        'title': 'Personalizacja',
                                        'link': {
                                            'id': '123',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/polecane/inspiracje/jeszcze-wiecej-personalizacji',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3815',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/3e7917d3-bf63-46c3-459c-67e235316100/public',
                                                    'width': 330,
                                                    'height': 330,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    }
                                ]
                            },
                            {
                                '__typename': 'ComponentSectionsProductsList',
                                'id': '243',
                                'section_title': 'Nowości',
                                'items': [
                                    {
                                        'id': '4531',
                                        'url_key': 'naszyjnik-z-zawieszka-icons-z-bialym-krysztalem',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'kolczyki-modulowe-icons-z-bialymi-krysztalami-w-ksztalcie-serca-pozlacane',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'pierscionek-icons-z-bialym-krysztalem-w-ksztalcie-serca-pozlacany',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'shopper-opowiesc-48-4-z-tkaniny-canvas-niebieska',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'naszyjnik-z-zielonymi-japonskimi-koralikami-miyuki-z-zawieszka-papillon',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'bransoletka-ze-zlotymi-japonskimi-koralikami-miyuki-z-zawieszka-slon',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'kolczyki-kola-z-niebieskimi-japonskimi-koralikami-miyuki-i-zawieszkami-pearls',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'naszyjnik-icons-pozlacany',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'kolczyki-sztyfty-azurowe-icons-z-czerwonymi-krysztalami-w-ksztalcie-serca-pozlacane',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'bransoletka-ze-zlotymi-japonskimi-koralikami-miyuki-z-zawieszka-slon',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'kolczyki-kola-z-niebieskimi-japonskimi-koralikami-miyuki-i-zawieszkami-pearls',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'bransoletka-z-matowym-medalikem-na-grubym-czarnym-sznurku',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '4531',
                                        'url_key': 'bransoletka-liana-stalowa-w-kolorze-ciemnobrazowym-z-sercem',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                ],
                                'rows': 1
                            },
                            {
                                '__typename': 'ComponentSectionsSlider',
                                'id': '1',
                                'items': [
                                    {
                                        'id': '104',
                                        'title': 'Biżuteria męska',
                                        'description': '',
                                        'color': '#ffffff',
                                        'bg': 'transparent',
                                        'alignment': 'start',
                                        'cta': {
                                            'id': '191',
                                            'text': 'Zobacz',
                                            'link': {
                                                'id': '658',
                                                'external_href': null,
                                                'target': 'blank',
                                                'text': null,
                                                'href': '/sklep/bizuteria-meska',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'variant': 'solid',
                                            'reversed': true,
                                            '__typename': 'ComponentPartsCta'
                                        },
                                        'link': null,
                                        'img': {
                                            'data': {
                                                'id': '3903',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/49a2fb07-8d03-4ac0-b765-649fcc46c300/public',
                                                    'width': 1920,
                                                    'height': 740,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        'img_mobile': {
                                            'data': {
                                                'id': '3902',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/7624459b-2007-439b-d38b-aefb05c5b700/public',
                                                    'width': 920,
                                                    'height': 920,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        'variant': null,
                                        'section_title': 'Biżuteria męska',
                                        '__typename': 'ComponentSectionsBanner'
                                    }
                                ]
                            },
                            {
                                '__typename': 'ComponentSectionsProductsList',
                                'id': '1',
                                'section_title': 'Bestsellery',
                                'items': [
                                    {
                                        'id': '2372',
                                        'url_key': 'kolczyki-flowers-na-sztyfcie-pozlacane-z-niezapominajkami-z-masy-perlowej',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '2372',
                                        'url_key': 'naszyjnik-z-pozlacana-azurowa-lilijka-na-lancuszku',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '2372',
                                        'url_key': 'kolczyki-bloom-pozlacane',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '2372',
                                        'url_key': 'naszyjnik-pioro-pozlacany',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '2372',
                                        'url_key': 'kolczyki-azurowe-koniczynki-pozlacane',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '2372',
                                        'url_key': 'choker-flowers-pozlacany-z-niezapominajkami-z-masy-perlowej',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '2372',
                                        'url_key': 'kolczyki-flowers-polkola-pozlacane',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '2372',
                                        'url_key': 'naszyjnik-nieskonczonosc-z-blaszka-pozlacany-54-cm',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                    {
                                        'id': '2372',
                                        'url_key': 'kolczyki-cube-1-7-cm-z-czarna-emalia-pozlacane',
                                        '__typename': 'ComponentPartsProduct'
                                    },
                                ],
                                'rows': 1
                            },
                            {
                                '__typename': 'ComponentSectionsGrid',
                                'id': '2',
                                'section_title': 'Zainspiruj się',
                                'items': [
                                    {
                                        'id': '154',
                                        'title': 'Wiosenne inspiracje',
                                        'link': {
                                            'id': '1027',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/polecane/inspiracje/spring-trends',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3904',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/d187a858-eb99-49a9-2382-8d089cc1e600/public',
                                                    'width': 689,
                                                    'height': 689,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                    {
                                        'id': '150',
                                        'title': 'Twój talizman szczęścia',
                                        'link': {
                                            'id': '1023',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/polecane/inspiracje/twoj-talizman-szczescia',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3869',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/54222998-2e9d-4dc7-e3d3-24c8d0c97800/public',
                                                    'width': 1200,
                                                    'height': 1200,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                    {
                                        'id': '151',
                                        'title': 'Ślubne inspiracje',
                                        'link': {
                                            'id': '1024',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/inspiracje/bizuteria-dla-panny-mlodej',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3906',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/f4958a0e-15bb-4f05-6188-f70211421d00/public',
                                                    'width': 689,
                                                    'height': 689,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                    {
                                        'id': '153',
                                        'title': 'Akcesoria',
                                        'link': {
                                            'id': '1026',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/torebki-i-akcesoria',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3820',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/cb22b24f-fa58-4b7e-5f0e-d280fb4c9900/public',
                                                    'width': 689,
                                                    'height': 689,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                    {
                                        'id': '151',
                                        'title': 'Ikony Lilou',
                                        'link': {
                                            'id': '1024',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/sklep/polecane/inspiracje/ikony-lilou',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'img': {
                                            'data': {
                                                'id': '3906',
                                                'attributes': {
                                                    'alternativeText': null,
                                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/ac3ada26-d073-45a1-fe54-953d75148c00/public',
                                                    'width': 689,
                                                    'height': 689,
                                                    '__typename': 'UploadFile'
                                                },
                                                '__typename': 'UploadFileEntity'
                                            },
                                            '__typename': 'UploadFileEntityResponse'
                                        },
                                        '__typename': 'ComponentPartsGridItem'
                                    },
                                ]
                            }
                        ],
                        'opinions': {
                            'id': '9',
                            'items': [],
                            '__typename': 'ComponentSpecialOpinionsSlider'
                        },
                        'video_mobile': null,
                        '__typename': 'HomePage'
                    }
                },
                '__typename': 'HomePageEntityResponse'
            },
            'newsletterBanner': {
                'data': {
                    'attributes': {
                        'title': 'Subskrybuj Nasz Newsletter!',
                        'description': 'Bądź na bieżąco z nowościami i ofertami specjalnymi',
                        'header': 'Dołącz do newslettera',
                        'content': 'Dowiedz się więcej o najnowszych kolekcjach, trendach i akcjach specjalnych Lilou.',
                        'terms_and_conditions': 'Podając swój adres email i klikając DOŁĄCZ akceptujesz Regulamin usługi Newsletter.Administratorem Twoich danych osobowych jest grupa firm LILOU (1.LILOU Sp. z o.o. ul. Filtrowa 50, 02-032 Warszawa, Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział Gospodarczy. KRS: 0000346352, NIP: 7010216745, kapitał zakładowy 5.000,00 zł; 2.LILOU RETAIL Sp. z o.o. Sp. k., ul. Filtrowa 50, 02-032 Warszawa, KRS 0000346352, REGON 142020870, NIP 7010216745; 3.LILOU ONLINE SHOP Sp. z o.o., Sp. k. ul. Filtrowej 50, 02-032 Warszawa, Sąd Rejonowy dla m.st. Warszaw w Warszawie, XII Wydział Gospodarczy, KRS: 0000663850, REGON: 366571012, NIP: 7010666632). Szczegółowe informacje na temat przetwarzania danych oraz przysługujących Ci prawach znajdziesz w Polityce Prywatności.',
                        'submit_btn': 'DOŁĄCZ',
                        'header_2': 'Dziękujemy za zapisanie na nasz newsletter!',
                        'content_2': 'Na podany adres e-mail została wysłana prośba o potwierdzenie subskrypcji.',
                        'close_btn': 'Zamknij',
                        'cta': {
                            'id': '151',
                            'text': 'DOŁĄCZ',
                            'link': {
                                'id': '1313',
                                'external_href': 'https://lilou.pl/information/newsletter',
                                'target': 'self',
                                'text': null,
                                'href': null,
                                'rel': null,
                                '__typename': 'ComponentPartsLink'
                            },
                            'variant': 'solid',
                            'reversed': true,
                            '__typename': 'ComponentPartsCta'
                        },
                        'img': {
                            'data': {
                                'id': '3918',
                                'attributes': {
                                    'alternativeText': null,
                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/d31f2ab2-08d9-4f99-ee4e-8488bf1fdc00/public',
                                    'width': 1835,
                                    'height': 401,
                                    '__typename': 'UploadFile'
                                },
                                '__typename': 'UploadFileEntity'
                            },
                            '__typename': 'UploadFileEntityResponse'
                        },
                        'img_2': {
                            'data': {
                                'id': '30',
                                'attributes': {
                                    'alternativeText': 'newsletter.jpg',
                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/2eaf747d-469a-4c9e-2f37-b3eb35e24f00/public',
                                    'width': 1200,
                                    'height': 1160,
                                    '__typename': 'UploadFile'
                                },
                                '__typename': 'UploadFileEntity'
                            },
                            '__typename': 'UploadFileEntityResponse'
                        },
                        'img_mobile': {
                            'data': {
                                'id': '3907',
                                'attributes': {
                                    'alternativeText': null,
                                    'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/558f0fa4-aae4-4ca9-f750-572836257800/public',
                                    'width': 384,
                                    'height': 504,
                                    '__typename': 'UploadFile'
                                },
                                '__typename': 'UploadFileEntity'
                            },
                            '__typename': 'UploadFileEntityResponse'
                        },
                        '__typename': 'NewsletterBanner'
                    },
                    '__typename': 'NewsletterBannerEntity'
                },
                '__typename': 'NewsletterBannerEntityResponse'
            }
        }
        if (locale === 'de') {
            data = {
                'homePage': {
                    'data': {
                        'id': '10',
                        '__typename': 'HomePageEntity',
                        'attributes': {
                            'hero': [
                                {
                                    'id': '185',
                                    'title': 'Magie für Deinen Look',
                                    'description': null,
                                    'color': '#ffffff',
                                    'bg': 'transparent',
                                    'alignment': 'start',
                                    'cta': {
                                        'id': '288',
                                        'text': 'Jetzt entdecken!',
                                        'link': {
                                            'id': '1379',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/shop/empfehlung/neuheiten',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'variant': 'solid',
                                        'reversed': true,
                                        '__typename': 'ComponentPartsCta'
                                    },
                                    'link': null,
                                    'img': {
                                        'data': {
                                            'id': '3911',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/fc6269f8-cd37-4ff4-959d-edaa26e44b00/public',
                                                'width': 1920,
                                                'height': 820,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'img_mobile': {
                                        'data': {
                                            'id': '3912',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/e45204a4-0f55-4d44-9d69-b9ac8f4f3700/public',
                                                'width': 1080,
                                                'height': 1613,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'variant': null,
                                    'section_title': 'Persönliche Geschenke zu Weihnachten',
                                    '__typename': 'ComponentSectionsBanner'
                                }
                            ],
                            'sections': [
                                {
                                    '__typename': 'ComponentSectionsGrid',
                                    'id': '22',
                                    'section_title': null,
                                    'items': [
                                        {
                                            'id': '135',
                                            'title': 'Armbänder',
                                            'link': {
                                                'id': '886',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/schmuckstuecke/armbaender',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3802',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/ad7cef06-07c1-4eb4-1fa3-1676ac9b7700/public',
                                                        'width': 689,
                                                        'height': 689,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '136',
                                            'title': 'Ohrringe',
                                            'link': {
                                                'id': '887',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/schmuckstuecke/ohrringe',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3805',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/b5c9ccdb-a4e2-49e5-068d-7e8f0082c700/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '137',
                                            'title': 'Ringe',
                                            'link': {
                                                'id': '888',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/schmuckstuecke/ringe',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3806',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/4f178186-b86a-44e0-5afa-a756ce35d700/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '139',
                                            'title': 'Halsketten',
                                            'link': {
                                                'id': '890',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/schmuckstuecke/halsketten',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3807',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/139bc0b5-c500-41aa-1876-6dad04bd4400/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '138',
                                            'title': 'Anhänger',
                                            'link': {
                                                'id': '889',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/schmuckstuecke/anhaenger.html',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3808',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/ff003d25-4c90-4707-542b-c0cda67e7f00/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        }
                                    ]
                                },
                                {
                                    '__typename': 'ComponentSectionsProductsList',
                                    'id': '270',
                                    'section_title': 'Bestseller',
                                    'items': [
                                        {
                                            'id': '4244',
                                            'url_key': 'armband-mit-vergoldetem-kleeblatt-etincelle-am-duennen-roten-schnur',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4245',
                                            'url_key': 'ohrringe-lilien-offen-gearbeitet-vergoldet',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4246',
                                            'url_key': 'ohrstecker-mit-dunklen-perlmutt-kleeblattern-0-8-cm-in-585er-gelbgold-fassung',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4247',
                                            'url_key': 'ohrringe-lilien-offen-gearbeitet-mit-zirkonen-vergoldet',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4248',
                                            'url_key': 'schnurarmband-rot-mit-etincelle-herz-vergoldet',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4249',
                                            'url_key': 'choker-etincelle-vergoldet',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4250',
                                            'url_key': 'ohrringe-soleil-vergoldet',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4251',
                                            'url_key': 'armband-hearts-vergoldet-16-cm',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4252',
                                            'url_key': 'armband-mit-perlen-vergoldet',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4253',
                                            'url_key': 'halskette-aus-turmalin-steinen-mit-anhanger-quarz-gruen-vergoldet',
                                            '__typename': 'ComponentPartsProduct'
                                        }
                                    ],
                                    'rows': 1
                                },
                                {
                                    '__typename': 'ComponentSectionsSlider',
                                    'id': '2',
                                    'items': [
                                        {
                                            'id': '126',
                                            'title': 'Echtgold Kollektion',
                                            'description': null,
                                            'color': '#ffffff',
                                            'bg': 'transparent',
                                            'alignment': 'start',
                                            'cta': {
                                                'id': '218',
                                                'text': 'Entdecken',
                                                'link': {
                                                    'id': '891',
                                                    'external_href': null,
                                                    'target': 'blank',
                                                    'text': null,
                                                    'href': '/shop/schmuckstuecke/585-Gold-Echtgold-Schmuck',
                                                    'rel': null,
                                                    '__typename': 'ComponentPartsLink'
                                                },
                                                'variant': 'solid',
                                                'reversed': true,
                                                '__typename': 'ComponentPartsCta'
                                            },
                                            'link': {
                                                'id': '284',
                                                'text': null,
                                                'link': {
                                                    'id': '1341',
                                                    'external_href': null,
                                                    'target': 'self',
                                                    'text': null,
                                                    'href': '/shop/schmuckstuecke/585-Gold-Echtgold-Schmuck',
                                                    'rel': null,
                                                    '__typename': 'ComponentPartsLink'
                                                },
                                                'variant': 'solid',
                                                'reversed': false,
                                                '__typename': 'ComponentPartsCta'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3186',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/fc4e5bf8-31b5-4fe7-39aa-3ef1e14f8000/public',
                                                        'width': 1920,
                                                        'height': 740,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            'img_mobile': {
                                                'data': {
                                                    'id': '3185',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/c41a8f37-4539-40d4-18cf-f0570068be00/public',
                                                        'width': 920,
                                                        'height': 920,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            'variant': null,
                                            'section_title': 'Echtgold Kollektion',
                                            '__typename': 'ComponentSectionsBanner'
                                        }
                                    ]
                                },
                                {
                                    '__typename': 'ComponentSectionsGrid',
                                    'id': '23',
                                    'section_title': 'Inspirationen für dich',
                                    'items': [
                                        {
                                            'id': '140',
                                            'title': 'Lilou Herzflügel',
                                            'link': {
                                                'id': '892',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/inspirations/lilou-herzflugel',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3913',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/0a8b5ddf-1fbf-45a6-b462-67b1a4021e00/public',
                                                        'width': 689,
                                                        'height': 689,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '142',
                                            'title': 'Geschenke für ihn',
                                            'link': {
                                                'id': '894',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/kollektionen/herrenkollektion-stones',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3914',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/05739076-cb3f-4e81-b30c-7b8d16e29200/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '143',
                                            'title': 'Now trending',
                                            'link': {
                                                'id': '895',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': 'shop/empfehlung/inspirationen/talismane-zum-fruehling',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3915',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/95a09f12-e2f2-4e49-2c0a-83502d18cf00/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '141',
                                            'title': 'Essentials',
                                            'link': {
                                                'id': '893',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/bestseller',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3916',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/07e88992-2df1-46bf-9d92-19bda59ffd00/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '144',
                                            'title': 'Sternzeichen',
                                            'link': {
                                                'id': '896',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/kollektionen/kollektion-zodiac',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3917',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/10538438-e581-403f-3659-228526d25500/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        }
                                    ]
                                },
                                {
                                    '__typename': 'ComponentSectionsInstashop',
                                    'id': '26',
                                    'head_script': '<script src="https://foursixty.com/media/scripts/fs.embed.v2.5.js" data-feed-id="lilou" data-theme="lookbook_v2_5" data-open-links-in-same-page="true" data-connector-filter="56863"></script>\n<style>.fs-has-links::after { padding:5px 7.5px;background-color:#ffffff;color:rgba(0, 0, 0, 0.8);content:"SHOP IT"; } .fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title { font-family:Times New Roman, serif;font-style:normal;font-weight:normal;font-size:14px; } div.fs-text-container .fs-entry-date, div.fs-detail-container .fs-post-info, div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text { font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-style:normal;font-weight:normal;font-size:14px; } .fs-wrapper div.fs-text-container * { color:#ffffff; } .fs-wrapper div.fs-text-container { background-color:rgba(0, 0, 0, 0.8);margin:0px; } div.fs-entry-date { display:none; } div.fs-entry-title { display:none; } .fs-lookbook_v2_5.fs-wrapper div.fs-timeline-entry { margin:1px; } </style>\n',
                                    'body_script': '<script src="https://foursixty.com/media/scripts/fs.embed.v2.5.js" data-feed-id="lilou" data-theme="lookbook_v2_5" data-open-links-in-same-page="true" data-connector-filter="56863"></script>\n<style>.fs-has-links::after { padding:5px 7.5px;background-color:#ffffff;color:rgba(0, 0, 0, 0.8);content:"SHOP IT"; } .fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title { font-family:Times New Roman, serif;font-style:normal;font-weight:normal;font-size:14px; } div.fs-text-container .fs-entry-date, div.fs-detail-container .fs-post-info, div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text { font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-style:normal;font-weight:normal;font-size:14px; } .fs-wrapper div.fs-text-container * { color:#ffffff; } .fs-wrapper div.fs-text-container { background-color:rgba(0, 0, 0, 0.8);margin:0px; } div.fs-entry-date { display:none; } div.fs-entry-title { display:none; } .fs-lookbook_v2_5.fs-wrapper div.fs-timeline-entry { margin:1px; } </style>\n'
                                }
                            ],
                            'opinions': null,
                            'video_mobile': null,
                            '__typename': 'HomePage'
                        }
                    },
                    '__typename': 'HomePageEntityResponse'
                },
                'newsletterBanner': {
                    'data': {
                        'attributes': {
                            'title': 'Join the Club! Erhalte 10% Rabatt auf deine erste Bestellung.',
                            'description': 'Look-Inspirationen I Exklusive Angebote I Schmuckneuheiten\n',
                            'header': 'Newsletter abonnieren',
                            'content': 'Erfahren Sie mehr über die neusten Kollektionen, Trends und Specials von Lilou.',
                            'terms_and_conditions': 'Indem Sie Ihre E-Mail-Adresse eingeben und auf JOIN klicken, akzeptieren Sie die Newsletter-Service-Bestimmungen.',
                            'submit_btn': 'Anmelden',
                            'header_2': 'Danke, dass Sie unseren Newsletter abonniert haben!',
                            'content_2': 'Eine Aufforderung zur Bestätigung Ihres Abonnements wurde an die angegebene E-Mail-Adresse gesendet.',
                            'close_btn': 'Schließen',
                            'cta': {
                                'id': '227',
                                'text': 'Anmelden',
                                'link': null,
                                'variant': 'solid',
                                'reversed': true,
                                '__typename': 'ComponentPartsCta'
                            },
                            'img': {
                                'data': {
                                    'id': '3918',
                                    'attributes': {
                                        'alternativeText': null,
                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/d31f2ab2-08d9-4f99-ee4e-8488bf1fdc00/public',
                                        'width': 1835,
                                        'height': 401,
                                        '__typename': 'UploadFile'
                                    },
                                    '__typename': 'UploadFileEntity'
                                },
                                '__typename': 'UploadFileEntityResponse'
                            },
                            'img_2': {
                                'data': {
                                    'id': '30',
                                    'attributes': {
                                        'alternativeText': 'newsletter.jpg',
                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/2eaf747d-469a-4c9e-2f37-b3eb35e24f00/public',
                                        'width': 1200,
                                        'height': 1160,
                                        '__typename': 'UploadFile'
                                    },
                                    '__typename': 'UploadFileEntity'
                                },
                                '__typename': 'UploadFileEntityResponse'
                            },
                            'img_mobile': {
                                'data': {
                                    'id': '3907',
                                    'attributes': {
                                        'alternativeText': null,
                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/558f0fa4-aae4-4ca9-f750-572836257800/public',
                                        'width': 384,
                                        'height': 504,
                                        '__typename': 'UploadFile'
                                    },
                                    '__typename': 'UploadFileEntity'
                                },
                                '__typename': 'UploadFileEntityResponse'
                            },
                            '__typename': 'NewsletterBanner'
                        },
                        '__typename': 'NewsletterBannerEntity'
                    },
                    '__typename': 'NewsletterBannerEntityResponse'
                }
            }
        }
        if (locale === 'fr') {
            data = {
                'homePage': {
                    'data': {
                        'id': '6',
                        '__typename': 'HomePageEntity',
                        'attributes': {
                            'hero': [
                                {
                                    'id': '190',
                                    'title': 'Collection Icons',
                                    'description': null,
                                    'color': '#ffffff',
                                    'bg': 'transparent',
                                    'alignment': 'start',
                                    'cta': {
                                        'id': '294',
                                        'text': 'Découvrir',
                                        'link': {
                                            'id': '1395',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/shop/collections/collection-icons',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'variant': 'solid',
                                        'reversed': true,
                                        '__typename': 'ComponentPartsCta'
                                    },
                                    'link': null,
                                    'img': {
                                        'data': {
                                            'id': '3628',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/ef393aba-5137-4c80-18c0-17972e421600/public',
                                                'width': 1080,
                                                'height': 461,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'img_mobile': {
                                        'data': {
                                            'id': '3600',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/0b972499-d51c-447f-a1e7-f0a95c592500/public',
                                                'width': 577,
                                                'height': 880,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'variant': null,
                                    'section_title': 'Collection Forza',
                                    '__typename': 'ComponentSectionsBanner'
                                },
                                {
                                    'id': '183',
                                    'title': '',
                                    'description': null,
                                    'color': '#ffffff',
                                    'bg': 'transparent',
                                    'alignment': 'start',
                                    'cta': {
                                        'id': '286',
                                        'text': 'Découvrir',
                                        'link': {
                                            'id': '1369',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/leprive',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'variant': 'solid',
                                        'reversed': true,
                                        '__typename': 'ComponentPartsCta'
                                    },
                                    'link': null,
                                    'img': {
                                        'data': {
                                            'id': '3909',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/b33df4ac-0add-4b82-9499-50bdaf850500/public',
                                                'width': 1920,
                                                'height': 820,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'img_mobile': {
                                        'data': {
                                            'id': '3896',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/825dc455-4ff2-435d-b751-6d7a0bdb8500/public',
                                                'width': 1080,
                                                'height': 1613,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'variant': null,
                                    'section_title': 'Colections Icons',
                                    '__typename': 'ComponentSectionsBanner'
                                },
                                {
                                    'id': '176',
                                    'title': 'Occasions spéciales',
                                    'description': null,
                                    'color': '#ffffff',
                                    'bg': 'transparent',
                                    'alignment': 'start',
                                    'cta': {
                                        'id': '273',
                                        'text': 'Découvrir',
                                        'link': {
                                            'id': '1284',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/shop/recommandes/inspirations/bijoux-pour-les-occasions-speciales',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'variant': 'solid',
                                        'reversed': true,
                                        '__typename': 'ComponentPartsCta'
                                    },
                                    'link': null,
                                    'img': {
                                        'data': {
                                            'id': '3629',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/fc6269f8-cd37-4ff4-959d-edaa26e44b00/public',
                                                'width': 1080,
                                                'height': 461,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'img_mobile': {
                                        'data': {
                                            'id': '3596',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/e45204a4-0f55-4d44-9d69-b9ac8f4f3700/public',
                                                'width': 577,
                                                'height': 880,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'variant': null,
                                    'section_title': 'Collection Planets',
                                    '__typename': 'ComponentSectionsBanner'
                                },
                                {
                                    'id': '176',
                                    'title': 'Ton talisman porte-bonheur',
                                    'description': null,
                                    'color': '#ffffff',
                                    'bg': 'transparent',
                                    'alignment': 'start',
                                    'cta': {
                                        'id': '273',
                                        'text': 'Découvrir',
                                        'link': {
                                            'id': '1284',
                                            'external_href': null,
                                            'target': 'self',
                                            'text': null,
                                            'href': '/shop/recommandes/inspirations/ton-talisman-porte-bonheur',
                                            'rel': null,
                                            '__typename': 'ComponentPartsLink'
                                        },
                                        'variant': 'solid',
                                        'reversed': true,
                                        '__typename': 'ComponentPartsCta'
                                    },
                                    'link': null,
                                    'img': {
                                        'data': {
                                            'id': '3629',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/c72ba714-e2ba-4552-805d-d1048f374500/public',
                                                'width': 1080,
                                                'height': 461,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'img_mobile': {
                                        'data': {
                                            'id': '3596',
                                            'attributes': {
                                                'alternativeText': null,
                                                'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/860f0d25-985e-439f-81da-09402736f500/public',
                                                'width': 577,
                                                'height': 880,
                                                '__typename': 'UploadFile'
                                            },
                                            '__typename': 'UploadFileEntity'
                                        },
                                        '__typename': 'UploadFileEntityResponse'
                                    },
                                    'variant': null,
                                    'section_title': 'Collection Planets',
                                    '__typename': 'ComponentSectionsBanner'
                                }
                            ],
                            'sections': [
                                {
                                    '__typename': 'ComponentSectionsGrid',
                                    'id': '11',
                                    'section_title': null,
                                    'items': [
                                        {
                                            'id': '64',
                                            'title': 'Boucles d’oreilles pour femmes',
                                            'link': {
                                                'id': '200',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/bijoux/boucles-d-oreilles',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3636',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/c4077575-7da2-4a63-5c81-669268e24000/public',
                                                        'width': 689,
                                                        'height': 689,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '65',
                                            'title': 'Bracelets pour femmes',
                                            'link': {
                                                'id': '201',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/bijoux/bracelets',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3633',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/25b05c94-3826-4a59-ecaa-7f4008ae2700/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '62',
                                            'title': 'Bagues pour femmes',
                                            'link': {
                                                'id': '198',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/bijoux/bagues',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3547',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/668c59d7-fcc4-4600-c368-a4b688613300/public',
                                                        'width': 1200,
                                                        'height': 1200,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '61',
                                            'title': 'Colliers pour femmes',
                                            'link': {
                                                'id': '197',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/bijoux/colliers',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3548',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/1dc408f4-b0dc-4f8e-7c7d-498dffd9d900/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '63',
                                            'title': 'Accessoires',
                                            'link': {
                                                'id': '199',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/sacs-et-accessoires/grands-sacs-a-main',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3549',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/653cb6da-4286-4135-a578-235568e46000/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        }
                                    ]
                                },
                                {
                                    '__typename': 'ComponentSectionsProductsList',
                                    'id': '312',
                                    'section_title': 'Nouveautés',
                                    'items': [
                                        {
                                            'id': '4424',
                                            'url_key': 'bracelet-liana-couleur-beige-dore-a-l-or-fin-avec-medaille',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4425',
                                            'url_key': 'bracelet-liana-couleur-noir-dore-a-l-or-fin-avec-quartz-noir',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4426',
                                            'url_key': 'bracelet-liana-couleur-gris-clair-dore-a-l-or-fin-avec-pendentif-lune',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4315',
                                            'url_key': 'bracelet-forza-en-resine-noire-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4316',
                                            'url_key': 'bague-forza-en-resine-grise-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4317',
                                            'url_key': 'bracelet-forza-en-resine-couleur-creme-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4318',
                                            'url_key': 'bracelet-forza-en-resine-marron-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4319',
                                            'url_key': 'bague-forza-en-resine-couleur-creme-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4265',
                                            'url_key': 'bracelet-avec-pierres-d-agate-indienne-et-son-pendentif-trefle-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4257',
                                            'url_key': 'shopper-mini-noir-motif-autruche',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4256',
                                            'url_key': 'sac-a-main-alice-noir-motif-autruche',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4239',
                                            'url_key': 'creoles-avec-pendentifs-planets-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4240',
                                            'url_key': 'bracelet-planets-avec-pierres-et-medaille-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4141',
                                            'url_key': 'creoles-2-1-cm-avec-email-couleur-turquoise-et-ses-pendentifs-soleil-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4140',
                                            'url_key': 'boucles-d-oreilles-cube-1-7-cm-avec-email-couleur-corail-et-ses-medailles-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3992',
                                            'url_key': 'shopper-histoire-48-4-en-toile-rouge',
                                            '__typename': 'ComponentPartsProduct'
                                        }
                                    ],
                                    'rows': 1
                                },
                                {
                                    '__typename': 'ComponentSectionsSlider',
                                    'id': '3',
                                    'items': [
                                        {
                                            'id': '138',
                                            'title': 'Bijoux pour hommes',
                                            'description': null,
                                            'color': '#ffffff',
                                            'bg': 'transparent',
                                            'alignment': 'start',
                                            'cta': {
                                                'id': '228',
                                                'text': 'Découvrir',
                                                'link': {
                                                    'id': '955',
                                                    'external_href': null,
                                                    'target': 'blank',
                                                    'text': null,
                                                    'href': '/shop/collections/collection-pour-hommes',
                                                    'rel': null,
                                                    '__typename': 'ComponentPartsLink'
                                                },
                                                'variant': 'solid',
                                                'reversed': true,
                                                '__typename': 'ComponentPartsCta'
                                            },
                                            'link': null,
                                            'img': {
                                                'data': {
                                                    'id': '3795',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/76ccf0f7-261b-4e32-e768-00abd35dce00/public',
                                                        'width': 1200,
                                                        'height': 514,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            'img_mobile': {
                                                'data': {
                                                    'id': '3796',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/55f93c1d-2678-4611-2b48-c5f668af8400/public',
                                                        'width': 425,
                                                        'height': 625,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            'variant': null,
                                            'section_title': 'Bracelets Liana',
                                            '__typename': 'ComponentSectionsBanner'
                                        }
                                    ]
                                },
                                {
                                    '__typename': 'ComponentSectionsProductsList',
                                    'id': '313',
                                    'section_title': 'Best-sellers',
                                    'items': [
                                        {
                                            'id': '3727',
                                            'url_key': 'parure-hearts-avec-coeur',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3267',
                                            'url_key': 'pendants-d-oreilles-lily-sertis-de-zircons-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3268',
                                            'url_key': 'bracelet-les-tresors-de-lilou-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3273',
                                            'url_key': 'collier-plume-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3276',
                                            'url_key': 'collier-infini-avec-plaque-dore-a-l-or-fin-54-cm',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3269',
                                            'url_key': 'boucles-d-oreilles-forza-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3270',
                                            'url_key': 'bracelet-anne-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3271',
                                            'url_key': 'pendants-d-oreilles-3-elements-ajoures-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '4097',
                                            'url_key': 'boucles-d-oreilles-luck-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3272',
                                            'url_key': 'collier-ras-de-cou-etincelle-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3274',
                                            'url_key': 'bracelet-hearts-dore-a-l-or-fin-16-3-5-cm',
                                            '__typename': 'ComponentPartsProduct'
                                        },
                                        {
                                            'id': '3275',
                                            'url_key': 'pendants-d-oreilles-lys-ajoure-dore-a-l-or-fin',
                                            '__typename': 'ComponentPartsProduct'
                                        }
                                    ],
                                    'rows': 1
                                },
                                {
                                    '__typename': 'ComponentSectionsGrid',
                                    'id': '24',
                                    'section_title': 'Inspirez-vous',
                                    'items': [
                                        {
                                            'id': '149',
                                            'title': 'Silver Winter',
                                            'link': {
                                                'id': '961',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/recommandes/inspirations/silver-winter',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3493',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/aba132d5-9380-4d36-5e84-0e9424de6900/public',
                                                        'width': 1200,
                                                        'height': 1200,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '145',
                                            'title': 'Bijoux porte-bonheur',
                                            'link': {
                                                'id': '957',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/bijoux/bijoux-porte-bonheur',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3552',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/79ce0f9c-8d43-4475-f8c4-9692a4d41200/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '148',
                                            'title': 'Les classiques Lilou',
                                            'link': {
                                                'id': '960',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/recommandes/best-sellers',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3553',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/ec180bf6-24ae-4252-f7b6-2bfa225a1000/public',
                                                        'width': 330,
                                                        'height': 330,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '146',
                                            'title': 'Sacs et accessoires',
                                            'link': {
                                                'id': '958',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/sacs-et-accessoires',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3116',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/760f4674-ae92-49e3-5b31-29a15799cb00/public',
                                                        'width': 1200,
                                                        'height': 1200,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        },
                                        {
                                            'id': '146',
                                            'title': 'Foulard Histoire 48.4',
                                            'link': {
                                                'id': '958',
                                                'external_href': null,
                                                'target': 'self',
                                                'text': null,
                                                'href': '/shop/sacs-et-accessoires/foulards-chale',
                                                'rel': null,
                                                '__typename': 'ComponentPartsLink'
                                            },
                                            'img': {
                                                'data': {
                                                    'id': '3116',
                                                    'attributes': {
                                                        'alternativeText': null,
                                                        'url': 'https://imagedelivery.net/YUhTm7roalNqH3L4i_CXLg/f710e3af-438f-4877-05b2-67ec38e72c00/public',
                                                        'width': 1200,
                                                        'height': 1200,
                                                        '__typename': 'UploadFile'
                                                    },
                                                    '__typename': 'UploadFileEntity'
                                                },
                                                '__typename': 'UploadFileEntityResponse'
                                            },
                                            '__typename': 'ComponentPartsGridItem'
                                        }
                                    ]
                                },
                                {
                                    '__typename': 'ComponentSectionsInstashop',
                                    'id': '23',
                                    'head_script': '<script src=“//foursixty.com/media/scripts/fs.embed.v2.5.js” data-feed-id=“lilou” data-theme=“sizes_v2_5” data-open-links-in-same-page=“true” data-page-size=“28”></script>\n<style>.fs-has-links::after { padding:5px 7.5px;background-color:#ffffff;color:rgba(0, 0, 0, 0.8);content:"SHOP IT""; } .fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title { font-family:Times New Roman, serif;font-style:normal;font-weight:normal;font-size:14px; } div.fs-text-container .fs-entry-date, div.fs-detail-container .fs-post-info, div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text { font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-style:normal;font-weight:normal;font-size:14px; } .fs-wrapper div.fs-text-container * { color:#ffffff; } .fs-wrapper div.fs-text-container { background-color:rgba(0, 0, 0, 0.8);margin:0px; } div.fs-entry-date { display:none; } div.fs-entry-title { display:none; } .fs-sizes_v2_5.fs-wrapper div.fs-timeline-entry { margin:1px; } </style>',
                                    'body_script': '<script src=“//foursixty.com/media/scripts/fs.embed.v2.5.js” data-feed-id=“lilou” data-theme=“sizes_v2_5” data-open-links-in-same-page=“true” data-page-size=“28”></script>\n<style>.fs-has-links::after { padding:5px 7.5px;background-color:#ffffff;color:rgba(0, 0, 0, 0.8);content: "SHOP IT"; } .fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title { font-family:Times New Roman, serif;font-style:normal;font-weight:normal;font-size:14px; } div.fs-text-container .fs-entry-date, div.fs-detail-container .fs-post-info, div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text { font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-style:normal;font-weight:normal;font-size:14px; } .fs-wrapper div.fs-text-container * { color:#ffffff; } .fs-wrapper div.fs-text-container { background-color:rgba(0, 0, 0, 0.8);margin:0px; } div.fs-entry-date { display:none; } div.fs-entry-title { display:none; } .fs-sizes_v2_5.fs-wrapper div.fs-timeline-entry { margin:1px; } </style><style>.fs-has-links::after { padding:4px 8px;color:#ffffff;content:"zobacz"; } .fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title { font-family:raleway, sans-serif;font-style:normal;font-weight:normal;font-size:14px; } div.fs-text-container .fs-entry-date, div.fs-detail-container .fs-post-info, div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text { font-family:Helvetica Neue, Helvetica, Arial, sans-serif;font-style:normal;font-weight:normal;font-size:14px; } .fs-wrapper div.fs-text-container * { color:#ffffff; } .fs-wrapper div.fs-text-container { background-color:rgba(0, 0, 0, 0.8);margin:0px; } div.fs-entry-date { display:none; } div.fs-entry-title { display:none; } .fs-lookbook_v2_5.fs-wrapper div.fs-timeline-entry { margin:1px; } div.fs-text-product-cta:after { content: "KUP TERAZ"; } </style>'
                                }
                            ],
                            'opinions': null,
                            'video_mobile': null,
                            '__typename': 'HomePage'
                        }
                    },
                    '__typename': 'HomePageEntityResponse'
                },
                'newsletterBanner': {
                    'data': null,
                    '__typename': 'NewsletterBannerEntityResponse'
                }
            }
        }
        setCms(data)
    }
    const [open, setOpen]: any = useState(false)
    const handleClose = () => {
        LocalStorageHelper.set('open', 'false')
        setOpen(false)
    }
    useEffect(() => {
        if (locale) {
            EdroneEvents.other(locale)
        }
    }, [locale])
    useEffect(() => {
        handleCms().then(() => {})
    }, [])
    useEffect(() => {
        if (cms) {
            setLoading(false)
        }
    }, [cms, hasNoIndex, hasNoFollow])
    return (
        <MagicLoader loadingOutSide={loading}>
            <Page
                isNavBarTransparent
                seo={{
                    title: SeoHelper.getTitle(),
                    description: SeoHelper.getDescription(),
                    openGraph: {
                        images: [{url: hero[0]?.img?.data?.attributes?.url ?? ''}],
                    },
                }}
                hasNoIndex={hasNoIndex}
                hasNoFollow={hasNoFollow}
            >
                {hero?.length ? <HeroSection hero={hero}/> : ''}
                {
                    locale === 'pl' ? (
                        <BannerPromotion
                            link={'/pl/inspiracje/celebracja-kobiecosci.html'}
                            background={'#f8b6c2'}
                            startDate={'2025-03-07 00:01'}
                            endDate={'2025-03-09 23:59'}
                            title={'Celebruj Dzień Kobiet z Lilou. Do  końca akcji pozostało:'}
                            subTitle={'Odbierz prezent do zakupów od 299 zł'}
                        />
                    ) : ''
                }
                {
                    locale === 'de' ? (
                        <TrustBanner />
                    ) : ''
                }
                <BodySections
                    sections={sections}
                    mobileVideoUrl={mobileVideoUrl}
                    mobileVideoCopy={mobileVideoCopy}
                />
                {opinions?.length ? <About slides={opinions}/> : ''}
                {newsletter && <Newsletter {...newsletter} />}
                {
                    open && (
                        <DialogHomePage
                            onClose={() => handleClose()}
                        />
                    )
                }
            </Page>
        </MagicLoader>
    )
}

export const getStaticProps = withAppInit(async (ctx: Context) => {
    return addApolloState(ctx.apolloClient, {
        props: {
            ...(await I18nHelper.getI18nProps(ctx, ['engraving'])),
        },
    })
}, true)

export default Index
